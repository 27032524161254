.lazy-title {
  color: black;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}


.lazy-subtitle {
  color: black;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .lazy-title {
    font-size: 80px; 
  }
  .lazy-subtitle {
    font-size: 30px;
  }
}
